import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MicrositesPage = () => (
  <Layout>
    <SEO title="Yams Studio: Microsites" />
    <div className="flex flex-wrap">
      <div className="w-full md:w-1/2 m-5">
        <p className="text-xl md:text-3xl leading-none">
          We’re building beautifully crafted microsites for independent
          creatives and small businesses.
        </p>
        <p className="text-xl md:text-3xl leading-none my-3">
          We’ll build you or your team a
          tiny website for a damn good price or in exchange for goods and services.
        </p>
        <p className="text-xl md:text-3xl leading-none my-3">
          We take one day out of the week every week to help people get their
          work out into the wild.
        </p>
        <p className="text-xl md:text-3xl leading-none">
          We currently have <span className="text-green-600">2</span> slots
          remaining for the month of August.
        </p>
      </div>
      <div className="w-full md:w-1/3 m-5">
        <h2 className="text-base md:text-lg leading-tight uppercase">
          Availability
        </h2>
        <p className="text-base md:text-lg leading-tight mt-2">
          Week of August 3&nbsp;&nbsp;
          <span className="text-sm text-orange-600 bg-orange-200 px-2 rounded-full">
            Unavailable
          </span>
        </p>
        <p className="text-base md:text-lg leading-tight mt-2">
          Week of August 10&nbsp;&nbsp;
          <span className="text-sm text-green-600 bg-green-200 px-2 rounded-full">
            Available
          </span>
        </p>
        <p className="text-base md:text-lg leading-tight mt-2">
          Week of August 17&nbsp;&nbsp;
          <span className="text-sm text-green-600 bg-green-200 px-2 rounded-full">
            Available
          </span>
        </p>
        <p className="text-base md:text-lg leading-tight mt-2">
          Week of August 24&nbsp;&nbsp;
          <span className="text-sm text-gray-600 bg-gray-200 px-2 rounded-full">
            Booked
          </span>
        </p>
        <h2 className="text-base md:text-lg leading-tight uppercase mt-8">
          Booking
        </h2>
        <p className="text-base md:text-lg leading-tight mt-2">
          To book a slot with us, first read our{" "}
          <Link
            className="text-blue-500 hover:text-blue-400 underline transition duration-150 ease-in-out"
            to="/microsites/terms-and-conditions"
          >
            terms and conditions
          </Link>{" "}
          then send a request with our{" "}
          <a
            className="text-blue-500 hover:text-blue-400 underline transition duration-150 ease-in-out"
            href="https://airtable.com/shrJ0fO6AIwNmrAlM"
            target="_blank"
            rel="noopener noreferrer"
          >
            form
          </a>
          .
        </p>
      </div>
    </div>
  </Layout>
)

export default MicrositesPage
